<div class="p-4 sm:p-6 lg:p-8">
  <div class="main-container grow">
    <div class="ev-h1 mb-6" i18n>My Profile</div>
    @if (userBasicInfo$ | async; as userBasicInfo) {
      <div class="sub-heading mb-8">
        <span>{{ helloText }}</span> {{ userBasicInfo?.firstName }}
        {{ userBasicInfo?.lastName }}!
        <span>{{ pageExplanationText }}</span>
      </div>
    } @else {
      <atom-progress-spinner class="mt-5"></atom-progress-spinner>
    }
    @if (!isLoadingUserInfoContents) {
      <cp-info-contents [content]="userInfoContents"></cp-info-contents>
    } @else {
      <atom-progress-spinner class="mt-5"></atom-progress-spinner>
    }
    @if (hasSalesRole$ | async) {
      <div class="mb-12" data-cy="company-info-notification">
        <h4>{{ companyInformationText }}</h4>
        <cp-current-locations></cp-current-locations>
        <atom-button (click)="onChangeActiveAddress()" class="mt-4">
          <span>{{ changeActiveAddresses }}</span>
        </atom-button>
        <ev-notification type="info" class="mt-4">
          <span>{{ selectLocationDescription }}</span>
        </ev-notification>
      </div>
    }

    @if (!!consentManagementLink) {
      <cp-consent-management
        [$link]="consentManagementLink"></cp-consent-management>
    }
  </div>
</div>
