import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'cp-info-contents',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './info-contents.component.html',
  styleUrls: ['./info-contents.component.scss'],
})
export class InfoContentsComponent {
  @Input() content: InfoContent[] = [];

  sectionHeading = $localize`Contact Information`;
}

export interface InfoContent {
  type: string;
  value: string | null;
}
