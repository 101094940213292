import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { UserInformationApiService } from '@ev-portals/cp/frontend/shared/api-client';
import { AuthenticationService } from '@ev-portals/cp/frontend/shared/auth/util';
import { CurrentLocationsComponent } from '@ev-portals/cp/frontend/shared/feature';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { NotificationComponent } from '@ev-portals/ev/frontend/ui-library';
import { notNullFilter } from '@ev-portals/ev/frontend/util';
import { combineLatest, take } from 'rxjs';

import { ConsentManagementComponent } from '../consent-management';
import { InfoContent, InfoContentsComponent } from '../info-contents';

@Component({
  selector: 'cp-profile-info',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    InfoContentsComponent,
    CurrentLocationsComponent,
    NotificationComponent,
    HttpClientModule,
    ConsentManagementComponent,
  ],
  templateUrl: './profile-info.component.html',
  styleUrl: './profile-info.component.scss',
})
export class ProfileInfoComponent {
  #authenticationService = inject(AuthenticationService);
  #userInformationApiService = inject(UserInformationApiService);
  #navigationService = inject(NavigationService);

  public hasSalesRole$ = this.#authenticationService.hasSalesRole$;

  userInfoContents: InfoContent[] = [];
  isLoadingUserInfoContents = true;

  helloText = $localize`Hello`;
  pageExplanationText = $localize`Here you can find your personal information.`;

  nameText = $localize`Name`;
  emailAddressText = $localize`Email Address`;
  accountNameText = $localize`Account Name`;
  companyInformationText = $localize`Company Information`;
  changeActiveAddresses = $localize`Change Active Addresses`;
  selectLocationDescription = $localize`Your selected location is used to determine which products you can see and buy. If you are missing something, please make sure to select the correct location. Otherwise you can also reach out to us via a request to add products to your portfolio.`;

  userBasicInfo$ = this.#authenticationService.user$.pipe(notNullFilter(), takeUntilDestroyed());
  userExtraInfo$ = this.#userInformationApiService.getUserExtraInfo().pipe(take(1));

  consentManagementLink: null | string = null;

  constructor() {
    this.#loadUserInfoContent();
  }

  #loadUserInfoContent(): void {
    this.isLoadingUserInfoContents = true;

    // Combine userBasicInfo$ and userExtraInfo$ observables
    combineLatest([this.userBasicInfo$, this.userExtraInfo$])
      .pipe(take(1))
      .subscribe(([userBasicInfo, userExtraInfo]) => {
        this.userInfoContents = [
          {
            type: this.nameText,
            value: `${userBasicInfo.firstName} ${userBasicInfo.lastName}`,
          },
          {
            type: this.emailAddressText,
            value: userBasicInfo.email,
          },
        ];

        if (userExtraInfo.salesforceAccountName) {
          this.userInfoContents.push({
            type: this.accountNameText,
            value: userExtraInfo.salesforceAccountName,
          });
        }

        this.consentManagementLink = userExtraInfo.consentManagementLink;

        this.isLoadingUserInfoContents = false;
      });
  }

  onChangeActiveAddress(): void {
    this.#navigationService.storeOriginalDestination();
    this.#navigationService.navigateToSelectLocation();
  }

  onClickManageConsents(): void {
    window.open(this.consentManagementLink as string, '_blank');
  }
}
