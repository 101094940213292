import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import {
  AccountAddressItem,
  AvailableAddressesResponseDto,
  SelectedLocationDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import {
  AddressType,
  NewSelectedLocation,
  SelectedLocationService,
} from '@ev-portals/cp/frontend/shared/auth/util';
import { LocationSelectionDialogComponent } from '@ev-portals/cp/frontend/shared/feature';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { first, take, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, LocationSelectionDialogComponent],
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss'],
})
export class LocationSelectionComponent {
  #selectedLocationService = inject(SelectedLocationService);
  #navigationService = inject(NavigationService);

  #currentlySelectedLocation = this.#selectedLocationService.$selectedLocation();
  #includedAddresses: AccountAddressItem[] = [];

  $newSelectedLocation = signal<NewSelectedLocation>({
    soldToKey: undefined,
    shipToKey: undefined,
    billToKey: undefined,
    payerKey: undefined,
  });

  #allMetaData: { [index in AddressType]: AddressDialogMetaData } = {
    soldToKey: {
      title: $localize`SoldTo Address`,
      subtitle: $localize`Please select your primary address`,
      addressType: AddressType.SoldTo,
    },
    shipToKey: {
      title: $localize`ShipTo Address`,
      subtitle: $localize`Please select your shipTo address`,
      addressType: AddressType.ShipTo,
    },
    billToKey: {
      title: $localize`BillTo Address`,
      subtitle: $localize`Please select your billTo address`,
      addressType: AddressType.BillTo,
    },
    payerKey: {
      title: $localize`Payer Address`,
      subtitle: $localize`Please select your payer address`,
      addressType: AddressType.Payer,
    },
  };

  public $addressDialogProps = signal<AddressDialogProps | null>(null);

  constructor() {
    this.#selectedLocationService.availableSoldToAddresses$
      .pipe(
        take(1),
        tap(availableSoldTos => {
          this.#getAvailableSoldToProps(availableSoldTos);
        }),
      )
      .subscribe();
  }

  #getAvailableSoldToProps(soldToAddresses: AvailableAddressesResponseDto): void {
    const addressDialogProps = this.mapAvailableAddressesResponseToAddressDialogProps(
      soldToAddresses,
      AddressType.SoldTo,
    );

    this.$addressDialogProps.set(addressDialogProps);
  }

  #getAvailableShipToProps(): void {
    this.#selectedLocationService
      .getAvailableShipTosFromBackend(this.$newSelectedLocation())
      .pipe(first())
      .subscribe(shipToResponse => {
        const addressDialogProps = this.mapAvailableAddressesResponseToAddressDialogProps(
          shipToResponse,
          AddressType.ShipTo,
        );
        this.$addressDialogProps.set(addressDialogProps);
      });
  }

  #getAvailableBillToProps(): void {
    this.#selectedLocationService
      .getAvailableBillTosFromBackend(this.$newSelectedLocation())
      .pipe(first())
      .subscribe(billToResponse => {
        const addressDialogProps = this.mapAvailableAddressesResponseToAddressDialogProps(
          billToResponse,
          AddressType.BillTo,
        );
        this.$addressDialogProps.set(addressDialogProps);
      });
  }

  #getAvailablePayerProps(): void {
    this.#selectedLocationService
      .getAvailablePayersFromBackend(this.$newSelectedLocation())
      .pipe(first())
      .subscribe(payerResponse => {
        const addressDialogProps = this.mapAvailableAddressesResponseToAddressDialogProps(
          payerResponse,
          AddressType.Payer,
        );
        this.$addressDialogProps.set(addressDialogProps);
      });
  }

  onLocationSelection({ address, addressType }: SelectedAddress): void {
    this.$newSelectedLocation.set({ ...this.$newSelectedLocation(), [addressType]: address.key });

    // we have to send included addresses back to backend so that impersonation mode works correctly (so it can be saved in cache)
    if (!this.#includedAddresses.find(includedAddress => includedAddress.key === address.key)) {
      this.#includedAddresses.push(address);
    }

    this.$addressDialogProps.set(null);

    switch (addressType) {
      case AddressType.SoldTo:
        this.#getAvailableShipToProps();
        break;

      case AddressType.ShipTo:
        this.#getAvailableBillToProps();
        break;

      case AddressType.BillTo:
        this.#getAvailablePayerProps();
        break;

      case AddressType.Payer:
        this.onLocationSelectionDialogClose();
        break;

      default:
        this.onLocationSelectionDialogClose();
        break;
    }
  }

  onLocationSelectionDialogClose(): void {
    this.#selectedLocationService.saveSelectedLocationInBackend(
      this.$newSelectedLocation(),
      this.#includedAddresses,
    );
    this.#navigationService.navigateToOriginalDestination();
  }

  private mapAvailableAddressesResponseToAddressDialogProps(
    availableAddresses: AvailableAddressesResponseDto,
    addressType: AddressType,
  ): AddressDialogProps {
    return {
      addresses: availableAddresses.items,
      metaData: this.#allMetaData[addressType],
      currentlySelectedLocation: this.#currentlySelectedLocation,
    };
  }
}

export interface AddressDialogMetaData {
  title: string;
  subtitle: string;
  addressType: AddressType;
}

interface AddressDialogProps {
  addresses?: AccountAddressItem[];
  metaData: AddressDialogMetaData;
  currentlySelectedLocation: SelectedLocationDto | null | undefined;
}

interface SelectedAddress {
  address: AccountAddressItem;
  addressType: AddressType;
}
