<cp-location-selection-dialog
  [$userAddresses]="$addressDialogProps()?.addresses"
  [$currentlySelectedLocation]="
    $addressDialogProps()?.currentlySelectedLocation
  "
  [$title]="$addressDialogProps()?.metaData?.title"
  [$subtitle]="$addressDialogProps()?.metaData?.subtitle"
  [$addressType]="$addressDialogProps()?.metaData?.addressType"
  [$hideCloseButton]="true"
  (addressSelection)="onLocationSelection($event)"
  data-cy="cp-location-selection-dialog">
</cp-location-selection-dialog>
