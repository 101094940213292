<section>
  <h4>{{ sectionHeading }}</h4>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
    @for (item of content; track item) {
      <div>
        <label class="font-semibold">{{ item.type }}</label>
        <p>{{ item.value }}</p>
      </div>
    }
  </div>
</section>
